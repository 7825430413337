<script setup>
import { FilterMatchMode } from "primevue/api";
import axios from "axios";
import { useToast } from "primevue/usetoast";

const toast = useToast();
const options = { year: "numeric", month: "numeric", day: "numeric" };

const getDateFormat = (date) => {
  date = new Date(Date.parse(date));
  return (
    date.toLocaleDateString(undefined, options) +
    " à " +
    date.toLocaleTimeString("fr-FR")
  );
};
</script>

<template>
  <div>
    <div class="card mb-4">
      <div class="flex align-items-center">
        <p class="text-4xl pr-4 pl-2 text-700">{{ $t('forum.customerForum') }}</p>
        <router-link :to="'/ouvrirDiscussion'" class="no-underline">
          <Button class="border-round-xl h-2rem">{{ $t('accueil.openNewChat') }}</Button>
        </router-link>
      </div>
      <div class="h-full p-2">
        <TabView :pt="{
          panelContainer: () => ({
            class: 'p-0',
          }),
        }">
          <TabPanel :header="$t('forum.myForums')" :pt="{
            header: () => ({
              class:
                'border-left-2 border-top-2 border-right-2 border-gray-300',
            }),
          }">
            <div class="border-2 border-gray-300 border-top-none">
              <DataTable ref="dt" :value="ticketsClient" :loading="loading" scrollable scrollHeight="600px" dataKey="id"
                 :pt="{
                  header: () => ({
                    class:
                      'bg-white border-top-none border-right-none border-left-none border-dashed border-2',
                  }),
                  footer: () => ({
                    class:
                      'bg-white border-round-bottom-2xl border-right-none border-left-none',
                  }),
                }">
                <Column field="key" header="N°" sortable style="min-width: 12rem" :pt="{
                  root: () => ({
                    class:
                      'bg-white border-top-none border-right-none border-left-none border-dashed border-2',
                  }),
                }"></Column>
                <Column field="fields.created" :header="$t('tickets.date')" style="min-width: 12rem" :pt="{
                  root: () => ({
                    class:
                      'bg-white border-top-none border-right-none border-left-none border-dashed border-2',
                  }),
                }">
                  <template #body="slotProps">
                    {{ getDateFormat(slotProps.data.fields.created) }}
                  </template>
                </Column>
                <Column :header="$t('tickets.section')" style="min-width: 12rem" :pt="{
                  root: () => ({
                    class:
                      'bg-white border-top-none border-right-none border-left-none border-dashed border-2',
                  }),
                }"><template #body="slotProps">
                    <div v-if="slotProps.data.fields.customfield_10082 !== null">
                      {{ tableau[slotProps.data.fields.customfield_10082.value]["label"] }}
                    </div>
                  </template></Column>
                <Column field="fields.summary" :header="$t('tickets.subject')" style="min-width: 12rem" :pt="{
                  root: () => ({
                    class:
                      'bg-white border-top-none border-right-none border-left-none border-dashed border-2',
                  }),
                }"></Column>
                <Column :header="$t('forum.consult')" style="min-width: 5rem" :pt="{
                  root: () => ({
                    class:
                      'bg-white border-top-none border-right-none border-left-none border-dashed border-2',
                  }),
                }">
                  <template #body="slotProps">
                    <div class="flex flex-wrap gap-3">
                      <router-link :to="'/ticket/' + slotProps.data.key">
                        <i class="pi pi-eye cursor-pointer"
                          style="color: rgb(9, 12, 198); font-size: 1.5rem" /></router-link>
                    </div>
                  </template>
                </Column>
              </DataTable>
            </div>
          </TabPanel>
          <TabPanel :header="$t('tickets.inProgress')" :pt="{
            header: () => ({
              class:
                'border-left-2 border-top-2 border-right-2 border-gray-300',
            }),
          }">
            <div class="border-2 border-gray-300 border-top-none">
              <DataTable ref="dt" :value="tickets" :loading="loading" scrollable scrollHeight="600px" dataKey="id"
                 :pt="{
                  header: () => ({
                    class:
                      'bg-white border-top-none border-right-none border-left-none border-dashed border-2',
                  }),
                  footer: () => ({
                    class:
                      'bg-white border-round-bottom-2xl border-right-none border-left-none',
                  }),
                }">
                <template #header>
                  <div class="flex flex-wrap gap-2 align-items-center justify-content-center">
                    <span class="p-inputgroup w-2"></span>
                    <span class="p-inputgroup flex-1">
                      <InputText v-model="filters['key'].value" :placeholder="'N° (' + $t('example.short') + ' : SSP-112)'" />
                      <Button icon="pi pi-search" @click="getForumsByFilter(false)"/>
                    </span>
                    <span class="p-inputgroup flex-1">
                      <Dropdown v-model="filters['rubrique'].value" showClear :options="rubriques" optionLabel="name" :placeholder="$t('tickets.section')" />
                      <Button icon="pi pi-search" @click="getForumsByFilter(false)"/>
                    </span>
                    <span class="p-inputgroup flex-1">
                      <InputText v-model="filters['sujet'].value" :placeholder="$t('tickets.subject')" />
                      <Button icon="pi pi-search" @click="getForumsByFilter(false)"/>
                    </span>
                    <span class="p-inputgroup w-2"> </span>
                  </div>
                </template>
                <Column field="key" header="N°" sortable style="min-width: 12rem" :pt="{
                  root: () => ({
                    class:
                      'bg-white border-top-none border-right-none border-left-none border-dashed border-2',
                  }),
                }"></Column>
                <Column field="fields.created" :header="$t('tickets.date')" style="min-width: 12rem" :pt="{
                  root: () => ({
                    class:
                      'bg-white border-top-none border-right-none border-left-none border-dashed border-2',
                  }),
                }">
                  <template #body="slotProps">
                    {{ getDateFormat(slotProps.data.fields.created) }}
                  </template>
                </Column>
                <Column :header="$t('tickets.section')" style="min-width: 12rem" :pt="{
                  root: () => ({
                    class:
                      'bg-white border-top-none border-right-none border-left-none border-dashed border-2',
                  }),
                }"><template #body="slotProps">
                    <div v-if="slotProps.data.fields.customfield_10082 !== null">
                      {{ tableau[slotProps.data.fields.customfield_10082.value]["label"] }}
                    </div>
                  </template></Column>
                <Column field="fields.summary" :header="$t('tickets.subject')" style="min-width: 12rem" :pt="{
                  root: () => ({
                    class:
                      'bg-white border-top-none border-right-none border-left-none border-dashed border-2',
                  }),
                }"></Column>
                <Column :header="$t('forum.consult')" style="min-width: 5rem" :pt="{
                  root: () => ({
                    class:
                      'bg-white border-top-none border-right-none border-left-none border-dashed border-2',
                  }),
                }">
                  <template #body="slotProps">
                    <div class="flex flex-wrap gap-3">
                      <router-link :to="'/ticket/' + slotProps.data.key">
                        <i class="pi pi-eye cursor-pointer"
                          style="color: rgb(9, 12, 198); font-size: 1.5rem" /></router-link>
                    </div>
                  </template>
                </Column>
                <template #footer v-if="!disabledTotal">
                  <div class="flex justify-content-end">
                      <Button @click="loadMore()" :label="$t('forum.loadMore')" icon="pi pi-plus"/>
                  </div>
                </template>
              </DataTable>
            </div>
          </TabPanel>
          <TabPanel :header="$t('tickets.archived')" :pt="{
            header: () => ({
              class:
                'border-left-2 border-top-2 border-right-2 border-gray-300',
            }),
          }">
            <div class="border-2 border-gray-300 border-top-none">
              <DataTable ref="dt" :value="ticketsArchive" :loading="loadingArchived" dataKey="id" 
                :pt="{
                header: () => ({
                  class:
                    'bg-white border-top-none border-right-none border-left-none border-dashed border-2',
                }),
                footer: () => ({
                  class:
                    'bg-white border-round-bottom-2xl border-right-none border-left-none',
                }),
              }">
                <template #header>
                  <div class="flex flex-wrap gap-2 align-items-center justify-content-center">
                    <span class="p-inputgroup w-2"></span>
                    <span class="p-inputgroup flex-1">
                      <InputText v-model="archivedFilters['key'].value" :placeholder="'N° (' + $t('example.short') + ' : SSP-112)'" />
                      <Button icon="pi pi-search" @click="getForumsByFilter(true)"/>
                    </span>
                    <span class="p-inputgroup flex-1">
                      <Dropdown v-model="archivedFilters['rubrique'].value" showClear :options="rubriques" optionLabel="name" :placeholder="$t('tickets.section')" />
                      <Button icon="pi pi-search" @click="getForumsByFilter(true)"/>
                    </span>
                    <span class="p-inputgroup flex-1">
                      <InputText v-model="archivedFilters['sujet'].value" :placeholder="$t('tickets.subject')" />
                      <Button icon="pi pi-search" @click="getForumsByFilter(true)"/>
                    </span>
                    <span class="p-inputgroup w-2"> </span>
                  </div>
                </template>
                <Column field="key" header="N°" sortable style="min-width: 12rem" :pt="{
                  root: () => ({
                    class:
                      'bg-white border-top-none border-right-none border-left-none border-dashed border-2',
                  }),
                }"></Column>
                <Column field="fields.created" header="Date" style="min-width: 12rem" :pt="{
                  root: () => ({
                    class:
                      'bg-white border-top-none border-right-none border-left-none border-dashed border-2',
                  }),
                }">
                  <template #body="slotProps">
                    {{ getDateFormat(slotProps.data.fields.created) }}
                  </template>
                </Column>
                <Column :header="$t('tickets.section')" style="min-width: 12rem" :pt="{
                  root: () => ({
                    class:
                      'bg-white border-top-none border-right-none border-left-none border-dashed border-2',
                  }),
                }"><template #body="slotProps">
                    <div v-if="slotProps.data.fields.customfield_10082 !== null">
                      {{ tableau[slotProps.data.fields.customfield_10082.value]["label"] }}
                    </div>
                  </template></Column>
                <Column field="fields.summary" :header="$t('tickets.subject')" style="min-width: 12rem" :pt="{
                  root: () => ({
                    class:
                      'bg-white border-top-none border-right-none border-left-none border-dashed border-2',
                  }),
                }"></Column>
                <Column :header="$t('forum.consult')" style="min-width: 5rem" :pt="{
                  root: () => ({
                    class:
                      'bg-white border-top-none border-right-none border-left-none border-dashed border-2',
                  }),
                }">
                  <template #body="slotProps">
                    <div class="flex flex-wrap gap-3">
                      <router-link :to="'/ticket/' + slotProps.data.key">
                        <i class="pi pi-eye cursor-pointer"
                          style="color: rgb(9, 12, 198); font-size: 1.5rem" /></router-link>
                    </div>
                  </template>
                </Column>
                <template #footer>
                  <div class="flex justify-content-end" v-if="!disabledArchived">
                    <Button @click="loadMoreArchived()" :label="$t('forum.loadMore')" icon="pi pi-plus" />
                  </div>
                </template>
              </DataTable>
            </div>
          </TabPanel>
        </TabView>
      </div>
    </div>
  </div>
  <Toast />
</template>
<script>
export default {
  data() {
    return {
      tickets: null,
      ticketsArchive: null,
      ticketsClient: null,
      filters: {},
      archivedFilters: {},
      disabledTotal: false,
      disabledArchived: false,
      loading: true,
      loadingArchived: true,
      rubriques: []
    };
  },
  created() {
    this.initFilters();
  },
  mounted() {
    this.tableau = JSON.parse(localStorage.getItem("libellesProduitsPHL"));
    //récupération du token backend dans le local storage
    this.token = localStorage.getItem("token");
    this.idClientPHL = localStorage.getItem("idTiers");
    let libelles = JSON.parse(localStorage.getItem("libellesProduitsPHL"));
    for(var key in libelles){
      if(libelles[key]["jira"] !== "0"){
        this.rubriques.push({"name": libelles[key]["label"], "code": key});
      }
    }

    axios
      .get(
        process.env.VUE_APP_URL_BACK_END + "/api/jira/public",
        {
          headers: {
            "Authorization": "Bearer " + this.token,
          },
        }
      )
      .then((response) => {
        this.tickets = response["data"]["tab"];
        this.disabledTotal = response["data"]["disabled"];
        this.loading = false;
      })
      .catch((e) => {
        //Token invalide ou expiré
        if(e['response']['status'] === 401){
            this.$router.push("/login");
        }
        //capture des erreurs revoyées par le back-end
        else{
            this.$toast.add({
                severity: "error",
                summary: this.$t('erreur.erreur'),
                detail: this.$t('erreur.erreurInterne'),
                life: 3000,
            });
        }
      });
    axios
      .post(
        process.env.VUE_APP_URL_BACK_END + "/api/jira/public",
        {
          archive: true
        },
        {
          headers: {
            //on envoie le token d'authentification et le format des données envoyés en POST
            "Content-Type": "multipart/form-data",
            "Authorization": "Bearer " + this.token,
          },
        }
      )
      .then((response) => {
        this.ticketsArchive = response["data"]["tab"];
        this.disabledArchived = response["data"]["disabled"];
        this.loadingArchived = false;
      })
      .catch((e) => {
        //Token invalide ou expiré
        if(e['response']['status'] === 401){
            this.$router.push("/login");
        }
        //capture des erreurs revoyées par le back-end
        else{
            this.$toast.add({
                severity: "error",
                summary: this.$t('erreur.erreur'),
                detail: this.$t('erreur.erreurInterne'),
                life: 3000,
            });
        }
      });
    axios
      .post(
        process.env.VUE_APP_URL_BACK_END + "/api/jira/publicByClient",
        {
          idClientPHL: this.idClientPHL
        },
        {
          headers: {
            //on envoie le token d'authentification et le format des données envoyés en POST
            "Content-Type": "multipart/form-data",
            "Authorization": "Bearer " + this.token,
          },
        }
      )
      .then((response) => {
        this.ticketsClient = response["data"]["issues"];
      })
      .catch((e) => {
        //Token invalide ou expiré
        if(e['response']['status'] === 401){
            this.$router.push("/login");
        }
        //capture des erreurs revoyées par le back-end
        else{
            this.$toast.add({
                severity: "error",
                summary: this.$t('erreur.erreur'),
                detail: this.$t('erreur.erreurInterne'),
                life: 3000,
            });
        }
      });
  },
  methods: {
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }

      return index;
    },
    initFilters() {
      this.filters = {
        sujet: { value: null, matchMode: FilterMatchMode.CONTAINS },
        key: { value: null, matchMode: FilterMatchMode.CONTAINS },
        rubrique: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
      this.archivedFilters = {
        sujet: { value: null, matchMode: FilterMatchMode.CONTAINS },
        key: { value: null, matchMode: FilterMatchMode.CONTAINS },
        rubrique: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    loadMore() {
      this.loading = true;
      axios
        .post(
          process.env.VUE_APP_URL_BACK_END + "/api/jira/public",
          {
            more: "true",
            startAt: this.tickets.length
          },
          {
            headers: {
              //on envoie le token d'authentification et le format des données envoyés en POST
              "Content-Type": "multipart/form-data",
              "Authorization": "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          this.tickets = this.tickets.concat(response["data"]["tab"]);
          this.disabledTotal = response["data"]["disabled"];
          this.loading = false;
        })
        .catch((e) => {
          //Token invalide ou expiré
          if(e['response']['status'] === 401){
              this.$router.push("/login");
          }
          //capture des erreurs revoyées par le back-end
          else{
              this.$toast.add({
                  severity: "error",
                  summary: this.$t('erreur.erreur'),
                  detail: this.$t('erreur.erreurInterne'),
                  life: 3000,
              });
          }
        });
    },
    loadMoreArchived() {
      this.loading = true;
      axios
        .post(
          process.env.VUE_APP_URL_BACK_END + "/api/jira/public",
          {
            more: "true",
            startAt: this.tickets.length,
            archive: true
          },
          {
            headers: {
              //on envoie le token d'authentification et le format des données envoyés en POST
              "Content-Type": "multipart/form-data",
              "Authorization": "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          this.ticketsArchive = this.ticketsArchive.concat(response["data"]["tab"]);
          this.disabledArchived = response["data"]["disabled"];
          this.loadingArchived = false;
        })
        .catch((e) => {
          //Token invalide ou expiré
          if(e['response']['status'] === 401){
              this.$router.push("/login");
          }
          //capture des erreurs revoyées par le back-end
          else{
              this.$toast.add({
                  severity: "error",
                  summary: this.$t('erreur.erreur'),
                  detail: this.$t('erreur.erreurInterne'),
                  life: 3000,
              });
          }
        });
    },
    getForumsByFilter(archived) {
      
      let filterSubjectValue = null;
      let filterKeyValue = null;
      let filterRubriqueValue = null;
      let params = {};
      
      if(archived === true){
        
        this.loadingArchived = true;
        
        if(this.archivedFilters.sujet.value !== null && this.archivedFilters.sujet.value !== ""){
          filterSubjectValue = this.archivedFilters.sujet.value;
        }
        if(this.archivedFilters.key.value !== null && this.archivedFilters.key.value !== "") {
          filterKeyValue = this.archivedFilters.key.value;
        }
        if(this.archivedFilters.rubrique.value !== null){
          filterRubriqueValue = this.archivedFilters.rubrique.value.code;
        }
        
        params = {
          filter_key: filterKeyValue,
          filter_subject: filterSubjectValue,
          filter_rubrique: filterRubriqueValue,
          archive: true
        };
        
      } else {
        
        this.loading = true;
        
        if(this.filters.sujet.value !== null && this.filters.sujet.value !== ""){
          filterSubjectValue = this.filters.sujet.value;
        }
        if(this.filters.key.value !== null && this.filters.key.value !== "") {
          filterKeyValue = this.filters.key.value;
        }
        if(this.filters.rubrique.value !== null){
          filterRubriqueValue = this.filters.rubrique.value.code;
        }
        
        params = {
          filter_key: filterKeyValue,
          filter_subject: filterSubjectValue,
          filter_rubrique: filterRubriqueValue,
        };
        
      }
      
      axios
      .post(
        process.env.VUE_APP_URL_BACK_END + "/api/jira/public",
        params,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": "Bearer " + this.token,
          },
        }
      )
      .then((response) => {
        if(archived){
          this.ticketsArchive = response["data"]["tab"];
          this.disabledArchived = response["data"]["disabled"];
          this.loadingArchived = false;
        } else {
          this.tickets = response["data"]["tab"];
          this.disabledTotal = response["data"]["disabled"];
          this.loading = false;
        }
      })
      .catch((e) => {
        this.loading = false;
        //Token invalide ou expiré
        if(e['response']['status'] === 401){
            this.$router.push("/login");
        }
        //capture des erreurs revoyées par le back-end
        else{
            this.$toast.add({
                severity: "error",
                summary: this.$t('erreur.erreur'),
                detail: this.$t('erreur.erreurInterne'),
                life: 3000,
            });
        }
      });
    },
  },
};
</script>
