<script setup>
import { useToast } from "primevue/usetoast";
import axios from "axios";
import { useRoute } from 'vue-router'

const toast = useToast();

const route = useRoute();

let icon = 'pi pi-upload';

//permet de traduire le ficher (à partir du lien blob) en variable exploitable par le back-end 
const customBase64Uploader = async (event) => {
  let click = document.getElementById('click');
  icon = 'pi pi-spin pi-spinner';
  const file = event.files[0];
  let extensionInterdite = file.name.lastIndexOf('.exe');
  if (extensionInterdite === -1){
    //envoie le fichier vers le back-end 
    axios
    .post(
      process.env.VUE_APP_URL_BACK_END + "/api/jira/moveFiles",
      {
        file: file,
        idTicket: route.params.idTicket,
        idClientPhl: localStorage.getItem("idTiers")
      },
      {
        headers: {
          //on envoie le token d'authentification et le format des données envoyés en POST
          "Content-Type": "multipart/form-data",
          "Authorization": "Bearer "+localStorage.getItem("token"),
        },
      }
      )
      .then((res) => {
        if (res.status === 200) {
          icon = 'pi pi-upload';
          toast.add({
            severity: "success",
            summary: "Succès",
            detail: "Pièce jointe uploadée",
            life: 3000,
          });
          click.focus();
        }
      })
      .catch((e) => {
        //Token invalide ou expiré
        if(e['response']['status'] === 401){
            this.$router.push("/login");
        }
        //capture des erreurs revoyées par le back-end
        else{
          toast.add({
            severity: "error",
            summary: this.$t('erreur.erreur'),
            detail: this.$t('erreur.erreurInterne'),
            life: 3000,
          });
          icon = 'pi pi-upload';
          click.focus();
        }
      });
    }
    else {
      toast.add({
        severity: "error",
        summary: "Fichier non autorisé (.exe)",
        life: 3000,
      });
      icon = 'pi pi-upload';
      click.focus();
    }
};
</script>
<template>
  <div class="px-4 py-8 md:px-6 lg:px-8">
    <div
      class="text-900 text-4xl font-normal border-round-top-2xl w-full md:w-12 p-3 border-left-2 border-right-2 border-top-2 border-500 text-gray-700 p-3">
      {{ $t("comment.commentTicket") }} {{ idTicket }}
    </div>
    <div class="border-round-bottom-2xl border-2 border-500 w-full md:w-12 p-2 md:p-5">
      <ul class="list-none w-12 p-0">
        <li class="flex flex-wrap pb-4 align-content-start">
          <p class="w-3">{{ $t("comment.comment") }}</p>
          <div class="flex w-9 flex-column">
            <Textarea :rows="7" :autoResize="true" v-model="comment" :class="{ 'p-invalid': errorComment }" />
            <small class="p-error">{{
              errorComment ? "Un commentaire est nécessaire" : "&nbsp;"
            }}</small>
          </div>
        </li>
        <li class="flex flex-wrap align-items-center">
          <p class="w-3">{{ $t("ticketHistory.attachment") }}</p>
          <FileUpload id="file" mode="basic"
            class="md:w-12rem p-button-outlined p-button-plain bg-indigo-500 text-0 border-round-2xl"
            :chooseLabel="$t('comment.add')" :auto="true" customUpload @uploader="customBase64Uploader">
            <template #uploadicon>        
              <i :class="icon"></i>   
            </template>  
          </FileUpload>
        </li>
        <li>
          <div class="flex justify-content-center pt-6">
            <Button type="submit" id="click"
              class="flex md:w-12rem border-round-2xl justify-content-center align-items-center w-auto mt-3"
              @click="envoyer()">{{ $t("comment.validate") }}</Button>
              <Button
              @click="supprFiles()"
                class="flex md:w-12rem border-round-2xl justify-content-center align-items-center ml-3 border-2 border-cyan-500 surface-0 text-cyan-500 w-auto mt-3">{{
                  $t("comment.cancel") }}</Button>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <Toast />
</template>
<script>
export default {
  data() {
    return {
      idTicket: "",
      comment: "",
      errorComment: false,
      infosDolibarr: "",
    };
  },
  mounted() {
    //récupération du token backend dans le local storage
    this.token = localStorage.getItem("token");
    //on récupère les informations du tiers
    this.idClientPHL = localStorage.getItem("idTiers");
    //récupération des informaions dolibarr a mettre avant le commentaire
    this.infosDolibarr = localStorage.getItem("infosDolibarr");
    //récupération du bouton retour du navigateur pour supprimer les fichiers
    var self = this;
    window.onpopstate = function(event) {
      self.supprFiles();
    }
    //on vérifie qu'il a le droit de commenter 
    if(localStorage.getItem("commentaire") !== undefined && localStorage.getItem("commentaire") === "ok"){
      this.idTicket = this.$route.params.idTicket
    }
    else{
      this.$router.push("/404");
    }
  },
  methods: {
    envoyer() {
      //on vérifie que le commentaire n'est pas vide et acceptable (au minimum 3 caractères)
      this.errorComment = this.comment.length < 3;
      if (!this.errorComment) {
        //permet de poster un commentaire sur un ticket Jira
        axios
          .post(
            process.env.VUE_APP_URL_BACK_END + "/api/jira/addComment",
            {
              //on envoie les paramètres au back-end
              idTicket: this.idTicket,
              comment: this.comment,
              infosDolibarr: this.infosDolibarr,
              idClientPhl: this.idClientPHL
            },
            {
              headers: {
                //on envoie le token d'authentification et le format des données envoyés en POST
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer "+this.token,
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              //si le statut de la requête est bon alors on indique à l'utilsateur la réussite de la requête
              this.$toast.add({
                severity: "success",
                summary: "Commentaire envoyé",
                life: 3000,
              }),
              //on enlève la variable permettant de savoir s'il a le droit de commenter sur ce ticket
              localStorage.removeItem("commentaire");
              // puis il est redirigé sur le ticket qu'il vient de
              this.$router.push("/ticket/" + this.idTicket);
            }
          }).catch((e) => {
            //Token invalide ou expiré
            if(e['response']['status'] === 401){
                this.$router.push("/login");
            }
            //capture des erreurs revoyées par le back-end
            else{
              this.$toast.add({
                severity: "error",
                summary: this.$t('erreur.erreur'),
                detail: this.$t('erreur.erreurInterne'),
                life: 3000,
              });
            }
          });
      }
    },
    //permet de supprimer le dossier contenant les pièces jointes que l'utilisateur à transmit avant d'annuler le ticket
    supprFiles() {
      axios.post(
        process.env.VUE_APP_URL_BACK_END + "/api/jira/supprFiles", 
        {
          //on envoie les paramètres au back-end
          client: this.idClientPHL,
          idTicket: this.idTicket
        },
        { 
          headers: { 
            //on envoie le token d'authentification et le format des données envoyés en POST
            "Content-Type": "multipart/form-data",
            "Authorization": "Bearer "+this.token
          }
        }
      ).then((res) => {
        if(res.status === 200){
          //si le statut de la requête est bon alors on renvoie l'utilisateur vers le ticket
          this.$router.push("/ticket/" + this.idTicket);
        }
      }).catch((e) => {
        //Token invalide ou expiré
        if(e['response']['status'] === 401){
            this.$router.push("/login");
        }
        //capture des erreurs revoyées par le back-end
        else{
          this.$toast.add({
            severity: "error",
            summary: this.$t('erreur.erreur'),
            detail: this.$t('erreur.erreurInterne'),
            life: 3000,
          });
        }
      })
    },
  },
};
</script>
