<template>
  <div
    id="sideNav"
    class="border-right-1 surface-border flex flex-column select-none left-0 transition-all transition-duration-400"
    style="width: 15vw;"
  >
    <div class="flex align-items-center justify-content-center hidden">
      <i
        id="icon-toggle-sidebar"
        :class="btnIcon + ' text-600 hover:text-blue-300 absolute mt-6 bg-white'"
        @click="toggleSidebar()"
        style="font-size: 1.5rem; left: 14.4vw; transition: 0.4s"
      ></i>
    </div>
    <div
      id="sidebar"
      class="surface-overlay top-0 left-0 h-full w-full overflow-y-auto mt-5"
    >
      <ul class="list-none p-2 my-0">
        <!-- ICI LA RACINE -->
        <li v-for="el in listMenu" :key="el.id">
          <router-link :to="el.href" class="no-underline">
          <a
            v-ripple
            class="flex no-underline align-items-center cursor-pointer border-round transition-colors transition-duration-150 p-ripple hover:text-900 md:flex-column md:text-center md:p-0 md:py-2 lg:p-2 lg:flex-row lg:text-left"
            :class="(this.$route.name == el.hrefName ? 'font-bold text-black-alpha-80	' : 'text-500 ') +  ' ' + padding + ' ' + justConCenter"
          >
            <i :class="el.icon" style="font-size: 2rem;"></i>
            <span class="sidenavItemName ml-5 md:ml-0 lg:ml-3">{{ el.name }}</span>
          </a>
          </router-link>
        </li>
        <li v-if="isAdminPhl">
          <router-link to="/selectionTiers" class="no-underline">
            <a
              v-ripple
              class="flex no-underline align-items-center cursor-pointer border-round transition-colors transition-duration-150 p-ripple hover:text-900 md:flex-column md:text-center md:p-0 md:py-2 lg:p-2 lg:flex-row lg:text-left"
              :class="(this.$route.name == '/selectionTiers' ? 'font-bold text-black-alpha-80	' : 'text-500 ') +  ' ' + padding + ' ' + justConCenter"
            >
              <i class="pi pi-building" style="font-size: 2rem;"></i>
              <span class="sidenavItemName ml-5 md:ml-0 lg:ml-3">Sélection du tiers</span>
            </a>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //ce tableau permet de générer les différents liens de la sidebar 
      listMenu: [
        {
          name: this.$t("sideBar.myAccount"),
          icon: "pi pi-users",
          href: "/compte/0",
          hrefName: 'compte'
        },
        {
          name: this.$t("sideBar.myTickets"),
          icon: "pi pi-tag",
          href: "/mesTickets",
          hrefName: 'mesTickets'
        },
        {
          name: this.$t("sideBar.myDownloads"),
          icon: "pi pi-download",
          href: "/telechargements",
          hrefName: '/telechargements'
        },
        {
          name: this.$t("sideBar.forums"),
          icon: "pi pi-comments",
          href: "/forum",
          hrefName: '/forum'
        },
        {
          name: this.$t("sideBar.techFiles"),
          icon: "pi pi-file-pdf",
          href: "/fichesTechniques",
          hrefName: '/fichesTechniques'
        },
      ],
      justConCenter: "",
      padding: "p-3",
      isOpen: true,
      btnIcon: "pi pi-chevron-circle-left",
      isAdminPhl: false,
    };
  },
  mounted() {
    this.isAdminPhl = localStorage.getItem('adminPhl') !== undefined && localStorage.getItem('adminPhl') === 'true';
  },
  methods: {
    toggleSidebar() {
      let sidenavItemName = document.querySelectorAll(".sidenavItemName");
      let sideNav = document.getElementById("sideNav");
      let toggleBtn = document.getElementById("icon-toggle-sidebar");

      if (this.isOpen) {
        // FERME
        this.justConCenter = "justify-content-center";
        sideNav.style.width = "5vw";
        toggleBtn.style.left = "4.3vw";
        //Ajoute la classe Hidden à chaque élément
        sidenavItemName.forEach((classEl) => {
          classEl.classList.add("hidden");
        });
        this.btnIcon = "pi pi-chevron-circle-right";
        this.isOpen = false;
      } else {
        // OUVERT
        this.justConCenter = "";
        sideNav.style.width = "15vw";
        toggleBtn.style.left = "14.25vw";
        //Retire la classe Hidden de chaque élément
        sidenavItemName.forEach((classEl) => {
          classEl.classList.remove("hidden");
        });
        this.btnIcon = "pi pi-chevron-circle-left";
        this.isOpen = true;
      }
    },
  },
};
</script>
<style>
#sideNav {
  width: 15vw;
}

.sidebarBtn {
  left: 14.25vw;
}

</style>