<script setup>
import { useToast } from "primevue/usetoast";

const toast = useToast();

const options = { year: "numeric", month: "numeric", day: "numeric" };

const getDateFormat = (date) => {
  date = new Date(Date.parse(date));
  return (
    date.toLocaleDateString(undefined, options) +
    " à " +
    date.toLocaleTimeString("fr-FR")
  );
};

const getAuthor = (content) => {
  return content.substring(1, content.indexOf("]"));
};

const getFirstComment = (comment, body) => {
  return comment.substring(comment.indexOf("]") + 1);
};

</script>
<template>
  <div class="surface-card p-2 w-full md:w-9 flex flex-column">
    <div>
      <div class="font-medium text-3xl text-600 mb-3">
        {{ $t("ticketHistory.ticket") }} n°{{ idTicket }}
      </div>
      <div class="font-medium text-3xl text-600 mb-5 flex justify-content-between">
        {{ $t("tickets.section") }} {{ rubrique }}
        <div class="flex justify-content-end gap-2">
          <div v-if="status !== idTicketTermine && idPHL === idClientPhl" class="flex gap-2">
            <div v-if="sub">
              <Button @click="unsubscribe()" class="h-full">{{ $t("ticketHistory.unfollow") }}</Button>
            </div>
            <div v-else>
              <Button label="Suivre" @click="subscribe()" class="h-full">{{ $t("ticketHistory.follow") }}</Button>
            </div>
            <Button @click="terminer()" outlined>{{ $t("ticketHistory.markAsFinish") }}</Button>
          </div>
          <div v-else-if="status === idTicketTermine">
            <Chip :label="$t('ticketHistory.closedTicket')" />
          </div>
        </div>
      </div>
    </div>
    <div>
      <div v-if="prive">
        <p class="text-red-500 font-bold">{{ $t("ticketHistory.warningPersonalAttachement") }}</p>
      </div>
    </div>
    <div class="flex border-right-2 border-top-2 border-bottom-2 border-500 border-round-xl"
      style="border-left: 1px solid !important; margin: 1em 0; border-left-width: 1em; border-left-color: var(--cyan-600) !important;">
        <ul class="list-none p-0 m-0 w-6">
          <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
            <div class="text-900 w-6 md:w-3 font-medium">{{ $t("ticketHistory.from") }}</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              {{ getAuthor(description) }}
            </div>
          </li>
          <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
            <div class="text-900 w-6 md:w-3 font-medium"></div>
            <div class="text-700 w-full md:w-8 md:flex-order-0 flex-order-1">
              {{ idPHL }}
            </div>
          </li>
          <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
            <div class="text-900 w-6 md:w-3 font-medium">{{ $t("ticketHistory.in") }}</div>
            <div class="text-700 w-full md:w-8 md:flex-order-0 flex-order-1">
              {{ getDateFormat(date) }}
            </div>
          </li>
          <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
            <div class="text-900 w-6 md:w-3 font-medium">{{ $t("tickets.subject") }}</div>
            <div class="text-700 w-full md:w-8 md:flex-order-0 flex-order-1">
              {{ sujet }}
            </div>
          </li>
          <li v-if="attachments[0] !== undefined" class="flex align-items-center py-3 px-2 surface-border flex-wrap">
            <div class="text-900 w-6 md:w-3 font-medium">
              {{ $t("ticketHistory.attachment") }}
            </div>
            <div class="text-700 w-full md:w-8 md:flex-order-0 flex-order-1">
              <div v-for="(attachment, index) in attachments[0]">
                <span v-if="(index + 1) < attachments[0].length">
                  <a :href="attachmentBackendURL + 'id=' + attachment.id + '&filename=' + attachment.filename">
                    {{ attachment.filename }}
                  </a>,
                </span>
                <span v-else>
                  <a :href="attachmentBackendURL + 'id=' + attachment.id + '&filename=' + attachment.filename">
                    {{ attachment.filename }}
                  </a>
                </span>
              </div>
            </div>
          </li>
        </ul>
        <li class="flex align-items-start py-3 px-2 surface-border w-6">
          <div class="text-900 md:w-2 font-medium mr-2">{{ $t("ticketHistory.message") }}</div>
          <div class="text-900 md:flex-order-0 flex-order-1 w-12">
            <Textarea type="text" :autoResize="true" rows="7" :value="getFirstComment(description)" disabled class="w-11 border-400 text-900" style="opacity: 1;"/>
          </div>
        </li>
      </div>
    </div>
    <div v-for="comment in comments" :key="comment" class="pr-2 w-9" :class="nomEntreprise === getAuthor(comment.body.content[0].content[0].text) ? ' pl-2 ' : ' pl-5 '">
      <div class="border-right-2 border-top-2 border-bottom-2 border-500 border-round-xl"
      style="border-left: 1px solid !important; margin: 1em 0; border-left-width: 1em;"
      :style=" nomEntreprise == getAuthor(comment.body.content[0].content[0].text) ? 'border-left-color: var(--cyan-600) !important;' : 'border-left-color: var(--indigo-500) !important;'">
      <div class="flex">
        <ul class="list-none p-0 m-0 w-6">
          <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
            <div class="text-900 w-6 md:w-3 font-medium">{{ $t("ticketHistory.from") }}</div>
            <div class="text-700 w-full md:w-8 md:flex-order-0 flex-order-1">
                <!-- Si l'auteur n'est pas un utilisateur API alors on met le nom de l'auteur depuis le Jira -->
                  {{ comment.author.displayName === "API1" || comment.author.displayName === "Studiodesign-dev" ? getAuthor(comment.body.content[0].content[0].text) : "PHL SOFT : " + comment.author.displayName }}
                </div>
          </li>
          <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
            <div class="text-900 w-6 md:w-3 font-medium">{{ $t("ticketHistory.in") }}</div>
            <div class="text-700 w-full md:w-8 md:flex-order-0 flex-order-1">
              {{ getDateFormat(comment.created) }}
            </div>
          </li>
          <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
            <div class="text-900 w-6 md:w-3 font-medium">Sujet</div>
            <div class="text-700 w-full md:w-8 md:flex-order-0 flex-order-1">
              {{ sujet }}
            </div>
          </li>
          <li v-if="attachments[comment.id] !== undefined" class="flex align-items-center py-3 px-2 surface-border flex-wrap">
            <div class="text-900 w-6 md:w-3 font-medium">
              {{ $t("ticketHistory.attachment") }}
            </div>
            <div class="text-700 w-full md:w-8 md:flex-order-0 flex-order-1">
              <div v-for="(attachment, index) in attachments[comment.id]">
                <span v-if="(index + 1) < attachments[comment.id].length">
                  <a v-if="(index + 1) < attachments[comment.id].length" :href="attachmentBackendURL + 'id=' + attachment.id + '&filename=' + attachment.filename">
                    {{ attachment.filename }}
                  </a>,
                </span>
                <span v-else>
                  <a :href="attachmentBackendURL + 'id=' + attachment.id + '&filename=' + attachment.filename">
                    {{ attachment.filename }}
                  </a>
                </span>
              </div>
            </div>
          </li>
        </ul>
        <li class="flex align-items-start py-3 px-2 surface-border w-6">
          <div class="text-900 w-6 md:w-2 font-medium  mr-2">{{ $t("ticketHistory.message") }}</div>
          <div class="text-900 w-full md:flex-order-0 flex-order-1 w-12 border-400 border-round border-1 p-2 pr-0" :id="'textarea_' + comment.id">
              {{ getComment(comment.body.content[0].content[0].text, comment.body, comment.id) }}
          </div>
        </li>
      </div>
    </div>
  </div>
  <div v-if="status !== idTicketTermine" class="flex pr-2 w-9 pl-2 mr-2 justify-content-end">
    <router-link :to="'/comment/' + idTicket" class="no-underline align-items-center">
      <Button class="border-round-2xl px-6">{{
        $t("ticketHistory.answer") }}</Button>
    </router-link>
  </div>
  <Toast />
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      idTicket: this.$route.params.idTicket,
      idPHL: null,
      idClientPhl: null,
      sujet: null,
      date: null,
      description: "",
      rubrique: null,
      status: null,
      comments: null,
      commentText: null,
      visibilite: null,
      attachments: [],
      mailClient: null,
      listeMail: null,
      listeMailCC: null,
      listeMailCCI: null,
      sub: null,
      idTicketTermine: "",
      attachmentBackendURL: process.env.VUE_APP_URL_BACK_END + '/getAttachment?',
      allAttachments: [],
      attachmentsPHL : [],
      nomEntreprise: "",
      prive: ""
    };
  },
  mounted() {
    //on récupère les détails du ticket
    this.idTicketTermine = process.env.VUE_APP_STATUS_JIRA_TICKET_TERMINE;
    this.tableau = JSON.parse(localStorage.getItem("libellesProduitsPHL"));
    this.idClientPhl = localStorage.getItem("idTiers");
    
    //récupération du token backend dans le local storage
    this.token = localStorage.getItem("token");
    axios
      .post(
        process.env.VUE_APP_URL_BACK_END + "/api/jira/detail",
        {
          idTicket: this.idTicket,
        },
        {
          headers: {
            //on envoie le token d'authentification et le format des données envoyés en POST
                "Content-Type": "multipart/form-data",
            "Authorization": "Bearer "+this.token,
          },
        }
      )
      .then((response) => {
        if (response["data"]["fields"]["customfield_10083"]["id"] === process.env.VUE_APP_ID_PUBLIC_JIRA || response["data"]["fields"]["customfield_10074"] === this.idClientPhl) {
          if (response["data"]["fields"]["comment"] !== undefined) {
            this.comments = response["data"]["fields"]["comment"]["comments"];
          }
          if (response["data"]["fields"]["attachment"].length !== 0) {
            this.allAttachments = response["data"]["fields"]["attachment"];

            // Tri des pièces jointes par commentaires
            this.allAttachments.forEach(attachment => {
              let idObjetAssocie = attachment.filename.substring(attachment.filename.indexOf('[')+1,attachment.filename.indexOf(']'));
              
              if(isNaN(parseInt(idObjetAssocie))){
                this.attachments[attachment['filename']] = attachment;
              }
              else{
                if(!Array.isArray(this.attachments[parseInt(idObjetAssocie)])) this.attachments[parseInt(idObjetAssocie)] = [];
                this.attachments[parseInt(idObjetAssocie)].push(attachment);
              }
            });

            this.comments.forEach(comment => {
              let idComment = comment.id;
              comment.body.content.forEach(partieCommentaire => {
                if(partieCommentaire.type !== undefined && partieCommentaire.type === 'mediaSingle'){
                  let attachmentName = partieCommentaire.content[0].attrs.alt;

                  if(!Array.isArray(this.attachments[parseInt(idComment)])) this.attachments[parseInt(idComment)] = [];
                  this.attachments[parseInt(idComment)].push(this.attachments[attachmentName]);
                  
                }
              })
            })
          }
          this.idPHL = response["data"]["fields"]["customfield_10074"];
          if(response["data"]["fields"]["customfield_10082"] !== null){
            this.rubrique = this.tableau[response["data"]["fields"]["customfield_10082"]["value"]]["label"];
          }
          this.sujet = response["data"]["fields"]["summary"];
          this.date = response["data"]["fields"]["created"];
          this.description =response["data"]["fields"]["description"]["content"][0]["content"][0]["text"];
          this.status = response["data"]["fields"]["status"]["id"];
          this.listeMail = response["data"]["fields"]["customfield_10079"];
          this.listeMailCC = response["data"]["fields"]["customfield_10080"];
          this.listeMailCCI = response["data"]["fields"]["customfield_10081"];
          this.mailClient = localStorage.getItem("mail");
          this.nomEntreprise = this.description.substring(1, this.description.indexOf("]"));
          this.visibilite = response["data"]["fields"]["customfield_10083"]["id"];

          // Vérification de l'abonnement du client dans la partie emailling
          this.sub = (this.listeMail !== null && this.listeMail.includes(this.mailClient)) || 
          (this.listeMailCC !== null && this.listeMailCC.includes(this.mailClient)) || 
          (this.listeMailCCI !== null && this.listeMailCCI.includes(this.mailClient));
          
          localStorage.setItem("commentaire", "ok");
        }
        else {
          this.$router.push('/404');
        }
        this.prive = this.visibilite === process.env.VUE_APP_ID_PUBLIC_JIRA;
        localStorage.setItem("commentaire", "ok");
      });
  },
  methods: {
    //permet d'ajouter le mail du client dans la liste des notifications du ticket
    subscribe() {
      axios
        .post(
          process.env.VUE_APP_URL_BACK_END + "/api/jira/changeMail",
          {
            mailClient: this.mailClient,
            idTicket: this.idTicket,
            emailAvant: this.listeMail,
            visibilite: this.visibilite
          },
          {
            headers: {
              //on envoie le token d'authentification et le format des données envoyés en POST
                "Content-Type": "multipart/form-data",
              "Authorization": "Bearer "+this.token,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            this.$toast.add({
              severity: "info",
              summary: "Mail ajouté",
              life: 3000,
            }),
              this.sub = true;
              this.listeMail = response['data']['mails'];
          }
        }).catch((e) => {
          //Token invalide ou expiré
          if(e['response']['status'] === 401){
              this.$router.push("/login");
          }
          //capture des erreurs revoyées par le back-end
          else{
            this.$toast.add({
              severity: "error",
              summary: this.$t('erreur.erreur'),
              detail: this.$t('erreur.erreurInterne'),
              life: 3000,
            });
          }
        });
    },
    //permet d'enlever le mail du client de la liste des notifications du ticket
    unsubscribe() {
      axios
        .post(
          process.env.VUE_APP_URL_BACK_END + "/api/jira/changeMail",
          {
            mailClient: this.mailClient,
            idTicket: this.idTicket,
            suppr: true,
            emailAvant: this.listeMail,
            visibilite: this.visibilite
          },
          {
            headers: {
              //on envoie le token d'authentification et le format des données envoyés en POST
                "Content-Type": "multipart/form-data",
              "Authorization": "Bearer "+this.token,
            },
          }
        )
        .then(
          (response) => {
            if (response.status === 200) {
              this.$toast.add({
                severity: "info",
                summary: "Mail supprimé",
                life: 3000,
              }),
                this.sub = false;
                this.listeMail = response['data']['mails'];
            }
          }
        ).catch((e) => {
          //Token invalide ou expiré
          if(e['response']['status'] === 401){
              this.$router.push("/login");
          }
          //capture des erreurs revoyées par le back-end
          else{
            this.$toast.add({
              severity: "error",
              summary: this.$t('erreur.erreur'),
              detail: this.$t('erreur.erreurInterne'),
              life: 3000,
            });
          }
        });
    },
    //permet de marquer le ticket comme terminé 
    terminer() {      
      axios
        .post(
          process.env.VUE_APP_URL_BACK_END + "/api/jira/terminateTicket",
          {
            idTicket: this.idTicket,
          },
          {
            headers: {
              //on envoie le token d'authentification et le format des données envoyés en POST
                "Content-Type": "multipart/form-data",
              "Authorization": "Bearer "+this.token,
            },
          }
        )
        .then(
          (response) => {
            if (response.status === 200) {
              this.$toast.add({
                severity: "info",
                summary: "Ticket terminé",
                life: 3000,
              })
            }
          }
        ).catch((e) => {
          //Token invalide ou expiré
          if(e['response']['status'] === 401){
            this.$router.push("/login");
          }
          //capture des erreurs revoyées par le back-end
          else if (e["messageErreur"] !== undefined) {
            this.$toast.add({
              severity: "error",
              summary: this.$t('erreur.erreur'),
              detail: this.$t('erreur.erreurInterne'),
              life: 3000,
            });
          }
        });
    },
    getComment(comment, body, id) {
      var div = document.createElement('div');
      let premiereLigne = comment.substring(comment.indexOf("]") + 1);
      if(body.content.length !== 1 || body.content[0].content.length !== 1){
        body.content.forEach(element => {
          if(element.content != undefined){
            element.content.forEach(texte => {
              if(texte.text != undefined){
                // Récupération des liens dans une balise a
                if(texte.marks !== undefined){
                  let a = document.createElement('a');
                  a.href = texte.marks[0].attrs.href;
                  a.innerText = texte.marks[0].attrs.href;
                  a.target = '_blank';
                  div.appendChild(a);
                }
                // Récupération du texte
                else{
                  let p = document.createElement('span');
                  p.innerText = texte.text;
                  div.appendChild(p);
                }
              }
              else{
                // Cas d'utilisation des volets d'informations Jira
                if (texte.content != undefined) {
                  texte.content.forEach(texteContainer => {
                    let p = document.createElement('p');
                    p.innerText = texteContainer.text; 
                    div.appendChild(p);
                  })
                }
                // Cas d'utilisation des liens internes vers les autres tickets Jira
                else if(texte.type === 'inlineCard'){
                  let lien = texte.attrs.url.split('/');
                  let p = document.createElement('span');
                  p.innerText = lien[lien.length - 1];
                  div.appendChild(p);
                }
                else{
                  div.appendChild(document.createElement('br'));
                }
              }
            });
          }
        });
      }
      else{
        let p = document.createElement('span');
        p.innerText = premiereLigne; 
        div.appendChild(p);
      }

      // On doit attendre que le DOM soit entièrement généré 
      this.$nextTick(() => {
        let textArea = document.getElementById("textarea_" + id);
        textArea.appendChild(div);
      });
    },
  }
}
</script>
