<template>
  <div>
    <div class="card mb-4">
      <p class="text-4xl pr-4 pl-2 text-700">{{ $t('sideBar.myDownloads') }}</p>
      <p v-if="fichiersActuels.length == 0 && fichiersObsoletes.length == 0" class="text-4xl pr-4 pl-2 text-700">{{ $t('telechargements.noDownload') }}</p>
      <TabView :pt="{
        panelContainer: () => ({
          class: 'p-0',
        })
      }">
        <TabPanel v-if="fichiersActuels.length != 0" :header="$t('telechargements.current')" :pt="{
          header: () => ({
            class:
              'border-left-2 border-top-2 border-right-2 border-gray-300',
          }),
        }">
          <DataTable :value="fichiersActuels" v-model:filters="filters" paginator :rows="5"
            :rowsPerPageOptions="[5, 10, 20, 50]" filterDisplay="row" :globalFilterFields="['filename']"
            tableStyle="min-width: 50rem" :pt="{
              header: () => ({
                class: 'bg-white border-right-none border-left-none border-dashed border-2'
              }),
              root: () => ({
                class: 'border-2 border-top-none border-gray-300'
              }),
            }">
            <Column field="filename" sortable :header='$t("fichesTechniques.filename")' :pt="{
              root: () => ({
                class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
              }),
              filterMenuButton: () => ({
                class: 'hidden'
              }),
              headerFilterClearButton: () => ({
                class: 'hidden'
              }),
            }">
              <template #filter="{ filterModel, filterCallback }">
                <div class="w-5">
                  <InputText v-model="filterModel.value" type="text" @input="filterCallback()" style="width:200%;"
                    :placeholder="$t('fichesTechniques.filter')" />
                </div>
              </template>
            </Column>
            <Column field="date" sortable :header='$t("fichesTechniques.date")' :pt="{
              root: () => ({
                class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
              }),
              filterMenuButton: () => ({
                class: 'hidden'
              }),
              headerFilterClearButton: () => ({
                class: 'hidden'
              }),
            }">
              <template #filter="{ filterModel, filterCallback }">
                <div class="w-5">
                  <InputText v-model="filterModel.value" type="text" @input="filterCallback()" style="width:200%;"
                    :placeholder="$t('fichesTechniques.filterDate')" />
                </div>
              </template>
            </Column>
            <Column field="version" sortable :header='$t("fichesTechniques.version")' :pt="{
              root: () => ({
                class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
              })
            }">
              <template #body="slotProps">
                <div class="flex flex-wrap gap-3">
                  {{ slotProps.data.version }}
                </div>
              </template>
            </Column>
            <Column field="os" sortable :header='$t("fichesTechniques.os")' :pt="{
              root: () => ({
                class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
              }),
              filterMenuButton: () => ({
                class: 'hidden'
              }),
              headerFilterClearButton: () => ({
                class: 'hidden'
              }),
            }">
              <template #filter="{ filterModel, filterCallback }">
                <div class="w-5">
                  <InputText v-model="filterModel.value" type="text" @input="filterCallback()" style="width:200%;"
                    :placeholder="$t('fichesTechniques.filterOs')" />
                </div>
              </template>
            </Column>
            <Column field="size" sortable :header='$t("fichesTechniques.size")' :pt="{
              root: () => ({
                class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
              })
            }">
              <template #body="slotProps">
                <div class="flex flex-wrap gap-3">
                  {{ slotProps.data.filename === '' ? '' : slotProps.data.size }}
                </div>
              </template>
            </Column>
            <Column field="link" :header='$t("fichesTechniques.download")' :pt="{
              root: () => ({
                class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
              })
            }">
              <template #body="slotProps">
                <div v-if="slotProps.data.filename !== ''" class="flex flex-wrap gap-3">
                  <a :href="`${this.linkTelechargement}name=${slotProps.data.filename}&hash=${slotProps.data.hash}&idTiers=${this.idTiers}&mail=${this.mailClient}`">
                    <i class="pi pi-download cursor-pointer" style="color: rgb(9, 12, 198); font-size: 1.5rem" />
                  </a>
                </div>
              </template>
            </Column>
            <Column field="infos" :header='$t("fichesTechniques.downloadInfos")' :pt="{
              root: () => ({
                class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
              })
            }">
              <template #body="slotProps">
                <div v-if="slotProps.data.filename !== ''" class="flex flex-wrap gap-3">
                  <div v-if="slotProps.data.infos">
                    <i @click="loadChangelog(slotProps.data.infos)" class="pi pi-eye cursor-pointer"
                      style="color: rgb(9, 12, 198); font-size: 1.5rem" />
                  </div>
                </div>
              </template>
            </Column>
            <Column field="installation" :header='$t("fichesTechniques.downloadInstall")' :pt="{
              root: () => ({
                class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
              })
            }">
              <template #body="slotProps">
                <div v-if="slotProps.data.filename !== ''" class="flex flex-wrap gap-3">
                  <a v-if="slotProps.data.installation">
                    <i @click="loadPdfModal(slotProps.data.installation, slotProps.data.note_install_hash, slotProps.data.note_install_id)"
                      class="pi pi-eye cursor-pointer" style="color: rgb(9, 12, 198); font-size: 1.5rem" />
                  </a>
                </div>
              </template>
            </Column>
          </DataTable>
        </TabPanel>
        <p>{{ fichiersObsoletes.length }}</p>
        <TabPanel v-if="fichiersObsoletes.length != 0" :header="$t('telechargements.deprecated')" :pt="{
          header: () => ({
            class:
            'border-left-2 border-top-2 border-right-2 border-gray-300',
          }),
        }">
          <DataTable :value="fichiersObsoletes" v-model:filters="filters" paginator :rows="5"
            :rowsPerPageOptions="[5, 10, 20, 50]" filterDisplay="row" :globalFilterFields="['filename']"
            tableStyle="min-width: 50rem" :pt="{
              header: () => ({
                class: 'bg-white border-right-none border-left-none border-dashed border-2'
              }),
              root: () => ({
                class: 'border-2 border-top-none border-gray-300'
              }),
            }">
            <Column field="filename" sortable :header='$t("fichesTechniques.filename")' :pt="{
              root: () => ({
                class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
              }),
              filterMenuButton: () => ({
                class: 'hidden'
              }),
              headerFilterClearButton: () => ({
                class: 'hidden'
              }),
            }">
              <template #filter="{ filterModel, filterCallback }">
                <div class="w-5">
                  <InputText v-model="filterModel.value" type="text" @input="filterCallback()" style="width:200%;"
                    :placeholder="$t('fichesTechniques.filter')" />
                </div>
              </template>
            </Column>
            <Column field="date" sortable :header='$t("fichesTechniques.date")' :pt="{
              root: () => ({
                class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
              }),
              filterMenuButton: () => ({
                class: 'hidden'
              }),
              headerFilterClearButton: () => ({
                class: 'hidden'
              }),
            }">
              <template #filter="{ filterModel, filterCallback }">
                <div class="w-5">
                  <InputText v-model="filterModel.value" type="text" @input="filterCallback()" style="width:200%;"
                    :placeholder="$t('fichesTechniques.filterDate')" />
                </div>
              </template>
            </Column>
            <Column field="version" sortable :header='$t("fichesTechniques.version")' :pt="{
              root: () => ({
                class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
              })
            }">
              <template #body="slotProps">
                <div class="flex flex-wrap gap-3">
                  {{ slotProps.data.version }}
                </div>
              </template>
            </Column>
            <Column field="os" sortable :header='$t("fichesTechniques.os")' :pt="{
              root: () => ({
                class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
              }),
              filterMenuButton: () => ({
                class: 'hidden'
              }),
              headerFilterClearButton: () => ({
                class: 'hidden'
              }),
            }">
              <template #filter="{ filterModel, filterCallback }">
                <div class="w-5">
                  <InputText v-model="filterModel.value" type="text" @input="filterCallback()" style="width:200%;"
                    :placeholder="$t('fichesTechniques.filterOs')" />
                </div>
              </template>
            </Column>
            <Column field="size" sortable :header='$t("fichesTechniques.size")' :pt="{
              root: () => ({
                class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
              })
            }">
              <template #body="slotProps">
                <div class="flex flex-wrap gap-3">
                  {{ slotProps.data.filename === '' ? '' : slotProps.data.size }}
                </div>
              </template>
            </Column>
            <Column field="link" :header='$t("fichesTechniques.download")' :pt="{
              root: () => ({
                class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
              })
            }">
              <template #body="slotProps">
                <div v-if="slotProps.data.filename !== ''" class="flex flex-wrap gap-3">
                  <a :href="`${this.linkTelechargement}name=${slotProps.data.filename}&hash=${slotProps.data.hash}`">
                    <i class="pi pi-download cursor-pointer" style="color: rgb(9, 12, 198); font-size: 1.5rem" />
                  </a>
                </div>
              </template>
            </Column>
            <Column field="infos" :header='$t("fichesTechniques.downloadInfos")' :pt="{
              root: () => ({
                class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
              })
            }">
              <template #body="slotProps">
                <div v-if="slotProps.data.filename !== ''" class="flex flex-wrap gap-3">
                  <div v-if="slotProps.data.infos">
                    <i @click="loadChangelog(slotProps.data.infos)" class="pi pi-eye cursor-pointer"
                      style="color: rgb(9, 12, 198); font-size: 1.5rem" />
                  </div>
                </div>
              </template>
            </Column>
            <Column field="installation" :header='$t("fichesTechniques.downloadInstall")' :pt="{
              root: () => ({
                class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
              })
            }">
              <template v-if="slotProps.data.filename !== ''" #body="slotProps">
                <div class="flex flex-wrap gap-3">
                  <a v-if="slotProps.data.installation">
                    <i @click="loadPdfModal(slotProps.data.installation, slotProps.data.note_install_hash, slotProps.data.note_install_id)"
                      class="pi pi-eye cursor-pointer" style="color: rgb(9, 12, 198); font-size: 1.5rem" />
                  </a>
                </div>
              </template>
            </Column>
          </DataTable>
        </TabPanel>
      </TabView>
    </div>
  </div>

  <Dialog v-model:visible="displayModalChangelog" modal maximizable dismissableMask :header="headerModalChangelog"
    :style="{ width: '70vw' }">
    <Accordion :multiple="true" :activeIndex="[0]">
      <AccordionTab v-for="log in changelog" :key="log.version" :header="log.version + ' - ' + log.date">
        <Accordion v-for="item in log.items">
          <AccordionTab :header="item.produit">
            <div v-for="note in item.items">
              <h2>{{ note.title }}</h2>
              <p v-for="text in note.items" :class="text.important ? 'text-red-500 font-bold' : ''">{{ text.text }}</p>
            </div>
          </AccordionTab>
        </Accordion>
      </AccordionTab>
    </Accordion>
  </Dialog>

  <Dialog v-model:visible="displayModalPdf" modal :header="headerModalPdf" :style="{ width: '70vw' }">
    <iframe :src="pdfData" width='100%' style="height:60vh;"></iframe>
  </Dialog>
</template>
  
<script>
import axios from "axios";
import { FilterMatchMode } from 'primevue/api';

export default {
  data() {
    return {
      idTiers: null,
      fichiers: [],
      fichiersActuels: [],
      fichiersObsoletes: [],
      displayModalPdf: false,
      pdfData: null,
      headerModalPdf: null,
      filters: {
        filename: { value: null, matchMode: FilterMatchMode.CONTAINS },
        date: { value: null, matchMode: FilterMatchMode.CONTAINS },
        os: { value: null, matchMode: FilterMatchMode.CONTAINS }
      },
      displayModalChangelog: false,
      headerModalChangelog: null,
      changelog: null
    };
  },
  mounted() {
    this.idTiers = localStorage.getItem("idTiers");
    this.mailClient = localStorage.getItem('mail');
    this.lang = this.$i18n.locale;
    this.token = localStorage.getItem('token');
    this.linkTelechargement = process.env.VUE_APP_URL_BACK_END + "/telechargement?";

    //on récupère la liste des fichiers téléchargeables
    axios
      .post(process.env.VUE_APP_URL_BACK_END + "/api/fichiers/listeDownloads", {
        idTiers: this.idTiers,
        lang: this.lang,
      }, {
        headers: {
          "Authorization": "Bearer " + this.token,
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        //Permet d'éviter un vue warn qui préfère un tableau vide à la place d'un string vide 
        if (response['data'] !== undefined && response["data"] !== "") {
          this.fichiers = response["data"];
          this.fichiers.forEach(fichier => {
            if (fichier.onglet == 1) {
              this.fichiersActuels.push(fichier);
            }
            else if(fichier.onglet == 99){
              this.fichiersObsoletes.push(fichier);
            }
          });
        }
      }).catch((e) => {
        //Token invalide ou expiré
        if (e['response']['status'] === 401) {
          this.$router.push("/login");
        }
        //capture des erreurs revoyées par le back-end
        else {
          this.$toast.add({
            severity: "error",
            summary: this.$t('erreur.erreur'),
            detail: this.$t('erreur.erreurInterne'),
            life: 3000,
          });
        }
      });
  },
  methods: {
    loadChangelog(infos) {
      this.displayModalChangelog = true;
      this.changelog = JSON.parse(infos);
      this.headerModalChangelog = "Informations";
    },
    loadPdfModal(nomPdf, hash, id) {
      axios
        .post(process.env.VUE_APP_URL_BACK_END + "/api/fichiers/elementB64", {
          nomPdf: nomPdf,
          hash: hash,
          id: id
        }, {
          headers: {
            "Authorization": "Bearer " + this.token,
            'Content-Type': 'multipart/form-data'
          },
        })
        .then((response) => {
          this.pdfData = "data:application/pdf;base64," + encodeURI(response.data);
          this.headerModalPdf = "Visualisation de " + nomPdf;
          this.displayModalPdf = true;
        })
        .catch((e) => {
          // Token invalide ou expiré
          if (e['response']['status'] === 401) {
            this.$router.push("/login");
          }
          //capture des erreurs revoyées par le back-end
          else {
            this.$toast.add({
              severity: "error",
              summary: this.$t('erreur.erreur'),
              detail: this.$t('erreur.erreurInterne'),
              life: 3000,
            });
          }
        });
    }
  }


}
</script>