<script setup>
import { useToast } from "primevue/usetoast";
const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
const toast = useToast();

const getDateFormat = (date) => {
    date = new Date(date * 1000);
    return (
        date.toLocaleDateString(undefined, options)
    );
};
</script>
<template>
    <div class="card">
        <DataTable :value="contrats" paginator :rows="5" sortField="date_contrat" :sortOrder="-1" :rowsPerPageOptions="[5, 10, 20, 50]" tableStyle="min-width: 50rem"
            :pt="{
                header: () => ({
                    class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
                }), root: () => ({
                    class: 'border-2 border-gray-300 border-top-none'
                })
            }">
            <Column field="ref_customer" :header="$t('contrats.idContract')" :pt="{
                root: () => ({
                    class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
                })
            }">
                <template #body="slotProps">
                    {{ slotProps.data.ref }}
                </template>    
            </Column>
            <Column field="ref_customer" :header="$t('facture.refClient')" :pt="{
                root: () => ({
                    class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
                })
            }"></Column>
            <Column field="date_contrat" :header="$t('facture.date')" :pt="{
                root: () => ({
                    class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
                })
            }">
                <template #body="slotProps">
                    {{ getDateFormat(slotProps.data.date_contrat) }}
                </template>
            </Column>
            <Column field="note_public" :header="$t('contrats.products')" :pt="{
                root: () => ({
                    class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
                })
            }">
                <template #body="slotProps">
                    <p>{{ getProduits(slotProps.data.lines) }}</p>
                </template>
            </Column>
            <Column field="note_public" :header="$t('contrats.amendmentRequest')" :pt="{
                root: () => ({
                    class: 'bg-white border-top-none border-right-none border-left-none border-dashed border-2'
                })
            }">
                <template #body="slotProps">
                    <!-- <div v-if="verifStatutContrat(slotProps.data)"> -->
                        <i class="pi pi-wrench cursor-pointer" @click="demandeAvenant(slotProps.data.ref, getProduits(slotProps.data.lines))" style="color: rgb(9, 12, 198); font-size: 1.5rem"></i>
                    <!-- </div> -->
                </template>
            </Column>
            
        </DataTable>
        <div v-if="doc" class="card border-round-bottom-2xl border-2 border-gray-300 border-top-none p-5">
            <div>
                <p>Pour accéder à la documentation PHL Mobile : </p>

                <p>Se connecter sur : <a :href="lienPhlMobileDoc">{{ lienPhlMobileDoc }}</a></p>

                <p>Renseigner le mot de passe : {{ motDePasse }}</p>
            </div>
        </div>
    </div>
    <Toast />
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            contrats: null,
            idTiers: "",
            doc: false,
            motDePasse: null,
            lienPhlMobileDoc: null
        };
    },
    mounted() {
        localStorage.removeItem("refContrat");
        // s'il y a un tiers payeur on prend son identifiant pour les contrats 
        if(localStorage.getItem('idTiersPayeur') !== 'undefined'){
            this.idTiers = localStorage.getItem('idTiersPayeur');
        }
        else{
            this.idTiers = localStorage.getItem("idTiers");
        }
        //idem pour le token
        this.token = localStorage.getItem("token");
        this.libelles = JSON.parse(localStorage.getItem("libellesProduitsPHL"));
        this.motDePasse = process.env.VUE_APP_MDP_PHL_MOBILE;
        this.lienPhlMobileDoc = process.env.VUE_APP_LIEN_PHL_MOBILE;
        //on récupère les contrats liées au tiers stocké en localstorage
        axios.post(process.env.VUE_APP_URL_BACK_END + "/api/dolibarr/contrats", {
                //on envoie les informations pour le back-end
                idTiers: this.idTiers,
            },
            {
                headers: {
                    //on envoie le token d'authentification et le format des données envoyés en POST
                    "Content-Type": "multipart/form-data",
                    "Authorization": "Bearer "+this.token,
                }
            })
            .then((response) => {
                //Permet d'éviter un vue warn qui préfère un tableau vide à la place d'un string vide 
                if (response["data"] !== "") {
                    this.contrats = response["data"];
                }
                else {
                    this.contrats = [];
                }
            }).catch((e) => {
                //Token invalide ou expiré
                if(e['response']['status'] === 401){
                    this.$router.push("/login");
                }
                //capture des erreurs revoyées par le back-end
                else{
                    this.$toast.add({
                        severity: "error",
                        summary: this.$t('erreur.erreur'),
                        detail: this.$t('erreur.erreurInterne'),
                        life: 3000,
                    });
                }
            });
    },
    methods: {
        demandeAvenant(refContrat, produits) {
            localStorage.setItem('refContrat', refContrat);
            localStorage.setItem('produits', produits);
            this.$router.push("/formulaireChangementMachine");
        },
        // Récupération des libellés des produits dans les lignes du contrat et traduction avec le tableau stocké en localStorage
        getProduits(lignesContrat){
            let produits = "";
            lignesContrat.forEach(ligne => {
                // Pour enlever les produits de backup
                if(parseInt(ligne.price) != 0){
                    produits += ligne.ref + ", "
                }
                if(ligne.ref.includes("PHL_Mobile")) {
                    this.doc = true;
                }
            });
            // On enlève le dernier /
            return (produits.substring(0, produits.length - 2));
        },
        // verifStatutContrat(contrat){
        //     contrat.lines.forEach(ligne => {
        //         if(parseInt(ligne.statut) !== 5){
        //             return false;
        //         } 
        //     });
        //     return true;
        // }
    },
};
</script>